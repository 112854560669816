import axios from "axios";
var CryptoJS = require("crypto-js");

class DataApi {
    constructor() {
        this.URL = "https://" + process.env.VUE_APP_MAIN_URL + "/api/contrat-gestion";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    get_material_liste(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/material/list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_contrat_informations(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_contrat_informations', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_sub_family_list(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_sub_family_list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_family_list(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_family_list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_clients(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_clients', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_contrats(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_contrats', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_parc(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_parc', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_stock(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_stock', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_or_liste(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_or_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_techniciens(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_techniciens', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_agences(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_agences', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    creat_new_or(oRdata, callback) {
        let datas = { oRdata }
        axios.post(this.URL + '/v1/creat_new_or', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    creat_new_moteur_line(motorData, callback) {
        let datas = { motorData }
        axios.post(this.URL + '/v1/creat_new_moteur_line', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_orListe_byLink(link, callback) {
        let datas = { link }
        axios.post(this.URL + '/v1/get_orListe_byLink', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    delete_or_by_id(id, callback) {
        let datas = { id }
        axios.post(this.URL + '/v1/delete_or_by_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    delete_or2_by_id(id, callback) {
        let datas = { id }
        axios.post(this.URL + '/v1/delete_or2_by_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }
}

export default new DataApi()